import React, { useEffect, useState } from 'react';
import { MFPayload } from '../../models/MFPayload';
import { Vehicle } from '../../store/slice/vehicle/vehicle.types';
import {
  ActionButtonsBox,
  AddNewNoteText,
  FinishButton,
  InspectionNotesBox,
  InspectionNotesComponentBox,
  InspectionNotesTextAreaBox,
  InspectionNotesTitleBox,
  InspectionNotesTitleText,
  InspectionNotesTopBox,
  RemainingCharacterText,
  SaveButton
} from './NotesStyle';
import { Typography } from '@interstate/components/Typography';
import { Alert } from '@interstate/components/Alert';
import { LineSeperatorHr, LineSeperatorHrBox } from '../common/CommonStyle';
import { Interstitial } from '@interstate/components/Interstitial';
import { STRING_CONSTANTS } from '../../constants/constants';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { postAndNavigate } from 'components/certification/certificationUtils';
import {
  computeHasChanges,
  setHasChanges,
  updateCertData,
  syncInitialState
} from 'store/slice/certification/certificationSlice';

type NotesProps = MFPayload & {
  locationCode?: string;
  onFinish: () => void;
  onSave: () => void;
  vehicleData: Vehicle | null;
};
const Notes: React.FC<any> = ({
  locationCode,
  onFinish,
  onSave,
  ...notesProps
}: NotesProps) => {
  const dispatch: AppDispatch = useDispatch();

  const options =
    useSelector((state: RootState) => state.location.data?.locations) || [];

  const { certData, hasChanges } = useSelector(
    (state: RootState) => state.certification
  );

  const [inspectionNotes, setInspectionNotes] = useState(
    certData?.certification?.notes || ''
  );
  const [remainingChars, setRemainingChars] = useState(
    STRING_CONSTANTS.NOTES_MAX_LENGTH -
      (certData?.certification?.notes?.length || 0)
  );

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFinishDisabled, setIsFinishDisabled] = useState(true);

  // eslint-disable-next-line
  const notesRestrictedCharsRegex = /[\/\\\'\"]/;

  useEffect(() => {
    setInspectionNotes(certData?.certification?.notes || '');
    setRemainingChars(
      STRING_CONSTANTS.NOTES_MAX_LENGTH -
        (certData?.certification?.notes?.length || 0)
    );
  }, [certData?.certification?.notes]);

  const handleChange = (e: any) => {
    const newNotes = e.target.value;
    const hasNotesRestrictedChars = notesRestrictedCharsRegex.test(newNotes);

    if (hasNotesRestrictedChars) {
      const sanitizedValue = newNotes.replace(notesRestrictedCharsRegex, '');
      setInspectionNotes(sanitizedValue);
      setRemainingChars(
        STRING_CONSTANTS.NOTES_MAX_LENGTH - sanitizedValue.length
      );
      setAlertMessage(
        'The characters /, \\, \', and " are not allowed in notes.'
      );
      setShowAlert(true);
      return;
    }
    setInspectionNotes(newNotes);
    setRemainingChars(STRING_CONSTANTS.NOTES_MAX_LENGTH - newNotes.length);

    dispatch(
      updateCertData({
        certification: {
          ...certData.certification,
          notes: newNotes
        }
      })
    );
    dispatch(computeHasChanges());
    setShowAlert(false);
  };

  useEffect(() => {
    const currentResponses = certData.certification.qnaData.questionResponses;
    const totalQuestions = certData.certification.qnaData.totalQuestions;
    const allQuestionsAnswered = currentResponses.length === totalQuestions;

    if (certData.processStatus !== 'PARTIAL' && !hasChanges) {
      setIsFinishDisabled(true);
    } else {
      setIsFinishDisabled(!allQuestionsAnswered);
    }
    // eslint-disable-next-line
  }, [
    certData.certification.qnaData.questionResponses,
    certData.processStatus,
    hasChanges
  ]);

  const handleSave = () => {
    postAndNavigate({
      certData,
      status: 'PARTIAL',
      locationCode,
      options,
      setLoading,
      setHasChanges: (value: boolean) => dispatch(setHasChanges(value)),
      setShowAlert,
      setAlertMessage,
      onSuccess: () => {
        dispatch(
          updateCertData({
            processStatus: 'PARTIAL'
          })
        );
        dispatch(syncInitialState());
        onFinish();
      }
    });
  };

  const handleFinish = () => {
    postAndNavigate({
      certData,
      status: 'SUBMITTED',
      locationCode,
      options,
      setLoading,
      setHasChanges: (value: boolean) => dispatch(setHasChanges(value)),
      setShowAlert,
      setAlertMessage,
      onSuccess: onFinish
    });
  };

  return (
    <InspectionNotesComponentBox>
      {loading && (
        <Interstitial
          data-testid='loading'
          fullScreen={true}
          size={2}
          message='Storing Notes'
        />
      )}
      <InspectionNotesTopBox>
        <InspectionNotesTitleBox>
          <InspectionNotesTitleText
            variant={'strong-md'}
            data-testid='inspection-notes-title-text'
          >
            {STRING_CONSTANTS.INSPECTION_NOTES_TITLE}
          </InspectionNotesTitleText>
        </InspectionNotesTitleBox>

        <LineSeperatorHrBox>
          <LineSeperatorHr tag={'hr'} variant={'strong-md'} />
        </LineSeperatorHrBox>

        <InspectionNotesBox>
          <AddNewNoteText variant={'strong-md'} data-testid='add-note-text'>
            {STRING_CONSTANTS.ADD_NEW_NOTE}
          </AddNewNoteText>

          <InspectionNotesTextAreaBox>
            {/*
              Interstate Textarea component is not working under tabs component that's why using textarea form react.
              test interstate component for textarea before changing
            */}
            <textarea
              id='inspection-notes'
              name='inspectionNotes'
              data-testid='inspection-notes'
              value={inspectionNotes}
              maxLength={STRING_CONSTANTS.NOTES_MAX_LENGTH}
              onChange={handleChange}
              rows={STRING_CONSTANTS.NOTES_BOX_ROWS}
              style={{
                width: '100%',
                fontSize: '16px',
                border: '1px solid #959595',
                borderRadius: '4px',
                resize: 'none',
                padding: '12px'
              }}
            />
          </InspectionNotesTextAreaBox>

          <RemainingCharacterText
            variant={'strong-md'}
            data-testid='add-note-text'
          >
            {remainingChars} {STRING_CONSTANTS.CHARACTER_REMAINING}
          </RemainingCharacterText>
        </InspectionNotesBox>
        {showAlert && (
          <Alert
            title='Error'
            type='error'
            className='alert-error'
            data-testid={'notes-alert-error'}
          >
            <Typography variant='body-md'>{alertMessage}</Typography>
          </Alert>
        )}
      </InspectionNotesTopBox>

      <ActionButtonsBox>
        <SaveButton
          buttonStyle='secondary'
          id='save-button_notes'
          data-testid='inspection-notes-save-button'
          onClick={handleSave}
          disabled={!hasChanges}
        >
          {STRING_CONSTANTS.SAVE}
        </SaveButton>

        <FinishButton
          buttonStyle='primary'
          id='finish-button_notes'
          data-testid='inspection-notes-finish-button'
          onClick={handleFinish}
          disabled={isFinishDisabled}
        >
          {STRING_CONSTANTS.FINISH}
        </FinishButton>
      </ActionButtonsBox>
    </InspectionNotesComponentBox>
  );
};
export default Notes;
