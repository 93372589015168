import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CertData,
  QnaData,
  QuestionResponse
} from 'services/certification/certification.types';

const initialCertData: CertData = {
  consignmentId: 'N/A',
  processStatus: '',
  certification: {
    startTime: new Date().toISOString(),
    endTime: '',
    locationCode: undefined,
    workOrderNumber: 'N/A',
    manheimAccountNumber: 'N/A',
    vin: 'N/A',
    userId: 'N/A',
    consignmentHref: 'N/A',
    unitHref: 'N/A',
    qnaData: {
      valid: false,
      prompting: false,
      questionResponses: [],
      announcements: [],
      totalQuestions: 0,
      questionnaireHref: '',
      metaData: []
    },
    notes: ''
  }
};

const certificationSlice = createSlice({
  name: 'certification',
  initialState: {
    certData: initialCertData,
    hasChanges: false,
    initialResponses: [] as QuestionResponse[],
    initialNotes: ''
  },
  reducers: {
    setCertData: (state, action: PayloadAction<CertData>) => {
      state.certData = action.payload;
    },
    updateCertData: (state, action: PayloadAction<Partial<CertData>>) => {
      state.certData = { ...state.certData, ...action.payload };
    },
    updateQnaData: (state, action: PayloadAction<QnaData>) => {
      state.certData.certification.qnaData = action.payload;
    },
    setInitialResponses: (state, action: PayloadAction<any[]>) => {
      state.initialResponses = action.payload;
    },
    setHasChanges: (state, action: PayloadAction<boolean>) => {
      state.hasChanges = action.payload;
    },
    setInitialNotes: (state, action: PayloadAction<string>) => {
      state.initialNotes = action.payload;
    },
    computeHasChanges: (state) => {
      const initialResponses = state.initialResponses;
      const currentResponses =
        state.certData.certification.qnaData.questionResponses;
      const initialNotes = state.initialNotes;
      const currentNotes = state.certData.certification.notes;

      const isNotesChange = initialNotes !== currentNotes;

      const isValueChange = !initialResponses
        ? false
        : initialResponses.length === 0
          ? true
          : initialResponses.some((row: any, i: number) => {
              if (row?.answers && row.answers.length > 0) {
                const currentResponse = currentResponses[i];

                if (
                  !currentResponse ||
                  !currentResponse.answers ||
                  currentResponse.answers.length === 0
                ) {
                  return false;
                }
                if (
                  row.answers[0]['value'] &&
                  row.answers[0].value !== currentResponse.answers[0].value
                ) {
                  return true;
                }
                if (
                  //   row.answers[0]['notes'] &&
                  row.answers[0].notes !== currentResponse.answers[0].notes
                ) {
                  return true;
                }
              }
              return false;
            });
      state.hasChanges = isValueChange || isNotesChange;
    },
    syncInitialState: (state) => {
      state.initialResponses = [
        ...state.certData.certification.qnaData.questionResponses
      ];
      state.initialNotes = state.certData.certification.notes;
      state.hasChanges = false;
    }
  }
});

export const {
  setCertData,
  updateCertData,
  updateQnaData,
  setInitialResponses,
  setHasChanges,
  setInitialNotes,
  computeHasChanges,
  syncInitialState
} = certificationSlice.actions;
export default certificationSlice.reducer;
