export const STRING_CONSTANTS = {
  APP_NAME: 'Cert Assist',
  CONTINUE: 'Continue',
  WELCOME_VEHICLE_SPLASH: 'Welcome to Vehicle Splash Page',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  FINISH: 'Finish',
  LOADING_CONFIG_FILES: 'Loading Config Files',
  QE_NOT_AVAILABLE: 'Question Engine Not Available',
  QE_ANNOUNCER_SOURCE: 'inspector',
  SAVE_INTERCEPT_WARNING_MESSAGE:
    'Your inspection progress since the last save will be lost.',

  //Error, warning strings
  INVALID_VEHICLE_ERROR_MESSAGE:
    'Invalid work order location combination. Please try again.',
  NO_RESPONSE_RECEIVED: 'No response received from the server',
  UNKNOWN_ERROR: 'An unknown error occurred',
  CERT_LANDING_TEXT: 'Certification Landing Page',
  DATA_NOT_AVAILABLE: 'Data Not Available',
  CERTIFICATION_EXIT_WARNING:
    'Are you sure you want to exit this certification without saving or submitting your answers?',
  EXIT_BEFORE_SAVING: 'Exit Before Saving?',

  // BFF URL
  HOST_PROXY_URL: '',
  APP_ID: 'cert-assist',

  // Inspection Notes View
  INSPECTION_NOTES_TITLE: 'Inspection Notes',
  ADD_NEW_NOTE: 'Add New Note (optional)',
  CHARACTER_REMAINING: 'characters remaining',
  NOTES_MAX_LENGTH: 250,
  NOTES_BOX_ROWS: 10,

  // vehicle checked out
  VEHICLE_CHECKEDOUT: 'Vehicle Checked Out',
  VEHICLE_CHECKEDOUT_DESC: 'This vehicle has been checked out of the auction.',
  VEHICLE_CHECKEDOUT_MSG: 'Are you sure you want to continue?',
  BACK: 'Back',
  CHECKED_OUT: 'CHECKED_OUT',
  PREVIOUSLY_INSPECTED: 'Previously Inspected'
};

export enum LocalStorageKeys {
  LOCATION = 'location'
}

export const COLORS = {
  LIGHT_SKY_BLUE: '#EBF6FF',
  DEEP_BLUE: '#005BA8',
  WHITE: '#FFFFFF'
};

export type ActivePage = 'HOME' | 'TABS';
