import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { STRING_CONSTANTS } from 'constants/constants';
import { Vehicle, VehicleState } from 'store/slice/vehicle/vehicle.types';
import axios from 'axios';
import { getBaseUrl } from 'utils/configLoader';

const initialState: VehicleState = {
  loading: false,
  data: null,
  error: null
};

export const fetchVehicle = createAsyncThunk<
  Vehicle,
  { location: string; workOrder: string },
  { rejectValue: string }
>('vehicle/fetchVehicle', async (params, { rejectWithValue }) => {
  const { location, workOrder } = params;

  try {
    const response = await axios.get<Vehicle>(
      `${getBaseUrl()}/vehicle/${location}/${workOrder}`
    );
    return response.data;
  } catch (error) {
    let errorMessage = STRING_CONSTANTS.UNKNOWN_ERROR;

    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorMessage =
          error.response.status === 404
            ? 'Invalid work order location combination. Please try again.'
            : 'Failed to fetch vehicle data';
      } else if (error.request) {
        errorMessage = STRING_CONSTANTS.NO_RESPONSE_RECEIVED;
      }
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }

    return rejectWithValue(errorMessage);
  }
});

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    resetVehicle(state) {
      state.data = null;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehicle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchVehicle.fulfilled,
        (state, action: PayloadAction<Vehicle>) => {
          state.loading = false;
          state.data = action.payload;

          //todo Remove mock method after bff integration
          // if (parseInt(state.data.workOrderNumber) % 2 === 0) {
          //   setMockDataForTesting(state);
          // }
        }
      )
      .addCase(fetchVehicle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

//todo Remove mock method after bff integration
// const setMockDataForTesting = (state: any) => {
//   state.data.certificationDetails = {
//     createdBy: 'JohnDoe',
//     createdOn: '03/21/2025',
//     updatedBy: 'JohnDoe',
//     updatedOn: '03/21/2025',
//     status: 'CERT_PENDING'
//   };
// };

export const { resetVehicle } = vehicleSlice.actions;
export default vehicleSlice.reducer;
