import { Box } from '@interstate/components/Box';
import { Button } from '@interstate/components/Button';
import { COLORS, STRING_CONSTANTS } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import './certification.css';
import { Interstitial } from '@interstate/components/Interstitial';
import { Typography } from '@interstate/components/Typography';
import { Alert } from '@interstate/components/Alert';
import { MFPayload } from 'models/MFPayload';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import {
  QnaData,
  QuestionResponse
} from 'services/certification/certification.types';
import { postAndNavigate } from './certificationUtils';
import {
  computeHasChanges,
  setHasChanges,
  setInitialResponses,
  setInitialNotes,
  updateCertData,
  updateQnaData,
  syncInitialState
} from 'store/slice/certification/certificationSlice';

declare global {
  interface Window {
    QuestionsAndAnnouncements?: any;
    NativeApp?: {
      showAlertForMenuSwitch: (showAlert: boolean, message: string) => void;
    };
  }
}
type CertificationProps = MFPayload & {
  locationCode?: string;
  onContinue: () => void;
  initialCertificationData?: any;
};

const Certification: React.FC<CertificationProps> = ({
  locationCode,
  onContinue,
  initialCertificationData,
  ...certificationProps
}: CertificationProps) => {
  const [loading, setLoading] = useState(true);
  const [savingLoading, setSavingLoading] = useState(false);
  const [qaAvailable, setQaAvailable] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const { certData, hasChanges } = useSelector(
    (state: RootState) => state.certification
  );
  const vehicleState = useSelector((state: RootState) => state.vehicle.data);
  const options =
    useSelector((state: RootState) => state.location.data?.locations) || [];

  const toUpperCase = (value: any): any => {
    return value ? value.toUpperCase() : undefined;
  };

  useEffect(() => {
    dispatch(
      updateCertData({
        consignmentId: vehicleState?.consignmentId || 'N/A',
        certification: {
          ...certData.certification,
          locationCode,
          workOrderNumber: vehicleState?.workOrderNumber || 'N/A',
          manheimAccountNumber: vehicleState?.manheimAccountNumber || 'N/A',
          vin: vehicleState?.vin || 'N/A',
          userId: toUpperCase(certificationProps.userDetails?.userId || 'N/A'),
          consignmentHref: vehicleState?.consignmentHref || 'N/A',
          unitHref: vehicleState?.unitHref || 'N/A'
        }
      })
    );
    // eslint-disable-next-line
  }, [
    dispatch,
    vehicleState,
    certificationProps.userDetails,
    locationCode,
    certData.certification.qnaData.questionResponses
  ]);

  useEffect(() => {
    if (window.NativeApp) {
      window.NativeApp.showAlertForMenuSwitch(
        true,
        STRING_CONSTANTS.SAVE_INTERCEPT_WARNING_MESSAGE
      );
    }
    return () => {
      window.NativeApp?.showAlertForMenuSwitch(false, '');
    };
  });

  const renderQuestions = (initialQuestionsResponse: QuestionResponse[]) => {
    if (!window.QuestionsAndAnnouncements) {
      console.error('QuestionsAndAnnouncements is not available.');
      setLoading(false);
      setQaAvailable(false);
      return;
    }

    const initialState = {
      announcements: [],
      questionResponses: initialQuestionsResponse
    };

    window.QuestionsAndAnnouncements.renderQuestions({
      onStateChange: (newState: any) => {
        if (loading) {
          setLoading(false);
          setShowButtons(true);
        }

        const questionResponses = newState.questionResponses.map(
          (response: any) => ({
            guid: response.guid,
            answers: response.answers,
            source: response.source,
            values: response.values,
            metadata: {
              updatedBy: toUpperCase(
                certificationProps.userDetails?.userId || 'N/A'
              )
            }
          })
        );

        const qnaData: QnaData = {
          valid: newState.valid,
          prompting: newState.prompting,
          questionResponses,
          announcements: newState.announcements || [],
          totalQuestions: newState.totalQuestions,
          questionnaireHref: newState.questionnaireHref || '',
          metaData: newState.metaData || []
        };

        dispatch(updateQnaData(qnaData));
        dispatch(computeHasChanges());
        setShowAlert(false);
        setShowSuccessAlert(false);
      },
      clientApplicationName: STRING_CONSTANTS.APP_NAME,
      rootElementId: 'cert-assist-questions-and-announcements-root',
      environment: certificationProps.appConfig?.qnaEnvironment,
      announcerSource: STRING_CONSTANTS.QE_ANNOUNCER_SOURCE,
      questionnaireHref: certificationProps.appConfig?.certFormUrl,
      initialState: initialState,
      colors: {
        activeButtonBackground: COLORS.LIGHT_SKY_BLUE,
        activeButtonBorder: COLORS.DEEP_BLUE,
        activeButtonText: COLORS.DEEP_BLUE,
        inactiveButtonBackground: COLORS.WHITE,
        inactiveButtonBorder: COLORS.DEEP_BLUE,
        inactiveButtonText: COLORS.DEEP_BLUE,
        progressIndicator: COLORS.DEEP_BLUE
      }
    });
  };

  useEffect(() => {
    if (initialCertificationData) {
      dispatch(
        updateCertData({
          processStatus: initialCertificationData.processStatus || '',
          certification: {
            ...initialCertificationData.certification,
            notes: initialCertificationData.certification.notes || ''
          }
        })
      );
      dispatch(
        setInitialResponses(
          initialCertificationData.certification.qnaData.questionResponses || []
        )
      );
      dispatch(
        setInitialNotes(initialCertificationData.certification.notes || '')
      );
      renderQuestions(
        initialCertificationData.certification.qnaData.questionResponses || []
      );
      dispatch(computeHasChanges());
    }
    // eslint-disable-next-line
  }, [initialCertificationData, dispatch]);

  const handleSave = () => {
    postAndNavigate({
      certData,
      status: 'PARTIAL',
      locationCode,
      options,
      setLoading: setSavingLoading,
      setHasChanges: (value: boolean) => dispatch(setHasChanges(value)),
      setShowAlert,
      setAlertMessage,
      onSuccess: () => {
        dispatch(
          updateCertData({
            processStatus: 'PARTIAL'
          })
        );
        dispatch(syncInitialState());
        setSuccessMessage('Saved vehicle record added to list of recents.');
        setShowSuccessAlert(true);
        setSavingLoading(false);
      }
    });
  };

  useEffect(() => {
    if (showSuccessAlert || showAlert) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [showSuccessAlert, showAlert]);

  return (
    <Box>
      <Box id='cert-assist-questions-and-announcements-root'></Box>
      {loading && (
        <Interstitial
          data-testid='loading'
          fullScreen={false}
          size={2}
          message={showButtons ? 'Storing answers' : 'Loading Questions'}
        />
      )}
      {savingLoading && (
        <Interstitial
          data-testid='saving-loading'
          fullScreen={true}
          size={2}
          message='Storing Answers'
        />
      )}
      {showAlert && (
        <Alert title='Error' type='error' className='alert-error'>
          <Typography variant='body-md'>{alertMessage}</Typography>
        </Alert>
      )}
      {showSuccessAlert && (
        <Alert title='Changes Saved' type='success' className='alert-success'>
          <Typography variant='body-md'>{successMessage}</Typography>
        </Alert>
      )}
      {!qaAvailable ? (
        <Typography
          variant={'h1'}
          data-testid='qe_not_available'
          className='error_card'
        >
          {STRING_CONSTANTS.QE_NOT_AVAILABLE}
        </Typography>
      ) : showButtons ? (
        <Box className='cert-buttons' component={'div'}>
          <Button
            buttonStyle='secondary'
            onClick={handleSave}
            disabled={!hasChanges}
            className='cert-buttons-save'
          >
            {STRING_CONSTANTS.SAVE}
          </Button>
          <Button
            buttonStyle='primary'
            onClick={onContinue}
            className={'cert-buttons-finish cert-buttons-finish-color'}
          >
            {STRING_CONSTANTS.CONTINUE}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default Certification;
